<template>
  <div class="createInvoice">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">Finance Weekly Report</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mt-2">
        <v-row class="pa-5">
          <v-col cols="4">
            <v-btn
              class="ml-1 mr-1"
              :loading="loading"
              :disabled="loading"
              color="primary"
              @click="getFinanceData(false)"
              >Load Finance Data</v-btn
            >
          </v-col>
          <v-col cols="4" align="center">
            <v-btn
              class="ml-1 mr-1 white--text"
              :loading="loading"
              :disabled="loading"
              color="teal"
              @click="getFinanceData(true)"
              >Export Report <v-icon dark> mdi-download </v-icon></v-btn
            >
          </v-col>
          <v-col cols="4" align="right">
            <v-btn
              class="ml-1 mr-1"
              :loading="loading"
              :disabled="loading"
              color="primary"
              @click="openDialog()"
              >Create Weekly Data</v-btn
            >
          </v-col>
        </v-row>
      </v-card>

      <v-card class="mt-10 pa-5" v-if="weeklyDataFormActive">
        <v-row>
          <v-col cols="12">
            <h2>Review Weekly Data</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="form.week_ending"
              label="Wk/Ending"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.week"
              type="number"
              label="Week"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.month"
              type="number"
              label="Month"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.year"
              type="number"
              label="Year"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
         <v-divider />
         <!-- warehouse -->
        <v-row>
          <v-col cols="3">
            <h1>Warehouse and Man hours: </h1>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.occupied_locations"
              type="number"
              label="Occupied Locations"
              hide-details
              outlined
              dense
              @change="computeTotalRevenue"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.total_locations"
              type="number"
              label="Total Locations"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.utilisation"
              type="number"
              label="Utilisation"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.man_hours"
              type="number"
              label="Man Hours"
              hide-details
              outlined
              dense
              @change="computeTotalRevenue"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.wh_revenue"
              type="number"
              label="WH Revenue"
              outlined
              dense
              hide-details
               @change="computeTotalRevenue"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.w_rev_per_mhr"
              type="number"
              label="WRev/Mhr"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider />
        <!-- orders, lines, pieces -->
        <v-row>
          <v-col cols="3">
            <h1>Orders: </h1>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.orders"
              type="number"
              label="Orders"
              hide-details
              outlined
              dense
              @change="computeTotalRevenue"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.lines"
              type="number"
              label="Lines"
              outlined
              dense
              hide-details
              @change="computeTotalRevenue"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.pieces"
              type="number"
              label="Pieces"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="form.distribution_revenue"
              type="number"
              label="Distribution Revenue"
              hide-details
              outlined
              dense
               @change="computeTotalRevenue"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.d_rev_per_ord"
              type="number"
              label="DRev/Ord"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.revenue"
              type="number"
              label="Freighter Revenue"
              hide-details
              outlined
              dense
               @change="computeTotalRevenue"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.storage"
              type="number"
              label="Storage"
              hide-details
              outlined
              dense
              @change="computeTotalRevenue"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.handling"
              type="number"
              label="Handling"
              hide-details
              outlined
              dense
              @change="computeTotalRevenue"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.handling_per_mhr"
              type="number"
              label="Handling/Mhr"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              v-model="form.handling_per_ord"
              type="number"
              label="Handling/Ord"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.rev_per_mhr"
              type="number"
              label="Rev/Mhr"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.rev_per_ord"
              type="number"
              label="Rev/Ord"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.rev_per_loc"
              type="number"
              label="Rev/Loc"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.strev_per_loc"
              type="number"
              label="Strev/Loc"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.total_units_stock"
              type="number"
              label="Total Units In Stock"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.units_shipped_vs_stock"
              type="number"
              label="Units Shipped Vs Stock"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>


          <v-col cols="3">
            <v-text-field
              v-model="form.rev_per_line"
              type="number"
              label="Rev/Line"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              v-model="form.activity_revenue_per_man_hour"
              type="number"
              label="ActivityRev/MHr"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              v-model="form.direct_revenue"
              type="number"
              label="Direct Revenue"
              hide-details
              outlined
              dense
              @change="computeTotalRevenue"
            ></v-text-field>
          </v-col>

           <v-col cols="3">
            <v-text-field
              v-model="form.admin_revenue"
              type="number"
              label="Admin Revenue"
              hide-details
              outlined
              dense
              @change="computeTotalRevenue"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
       
            <v-col cols="6">
            <h1>Total Revenue (Warehouse + Distribution + Freighter + Admin): </h1>
          </v-col>
       
          <v-col cols="3">
            <v-text-field
              v-model="form.total_rev"
              type="number"
              label="Total Rev"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>


          <v-col cols="3">
            <v-checkbox
              class="ma-0 pt-2"
              v-model="formUpdateInvoice"
              label="Overwrite if exists"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4"> </v-col>
          <v-col cols="4" align="center">
            <v-btn
              class="ml-1 mr-1"
              :loading="loading"
              :disabled="loading"
              outlined
              color="primary"
              @click="cancelWeeklyForm()"
              >Cancel</v-btn
            >
            <v-btn
              class="ml-1 mr-1"
              :loading="loading"
              :disabled="loading"
              color="primary"
              @click="submitWeeklyData()"
              >Submit</v-btn
            >
          </v-col>
          
          <v-col cols="4"> </v-col>
        </v-row>
      </v-card>

      <v-card class="mt-10 pa-5" v-if="this.financeData.length > 0">
        <h2>Finance Data</h2>
        <v-row>
          <v-col cols="6" class="px-5">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="financeHeaders"
              :items="financeData"
              :items-per-page="5"
              :search="search"
              id="data-table"
            >
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editWeeklyData(item)">edit</v-icon>
          </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Create Weekly Data</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6" class="pt-5">
                <v-select
                  v-model="invoiceNumber"
                  :items="invoiceNumbers"
                  item-text="invoiceNumbers"
                  label="Select Invoice Number"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="manHours"
                  label="Man Hours"
                  type="number"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="createWeeklyData"
              >Create Weekly Data</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar v-model="snackbar" :timeout="3000">
        {{ text }}
        <v-btn color="blue" text @click="snackbar = false"></v-btn>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import FinanceService from "@/services/Finance.js";
import CommitedData from "@/services/CommitedData.js";
import * as XLSX from 'xlsx'

export default {
  data() {
    return {
      // Loading
      loading: false,
      // Snackbar
      snackbar: false,
      text: "",
      response: null,
      search: "",

      dialog: false,

      //dialog fields to retrieve weekly data
      invoiceNumbers: [],
      invoiceNumber: "",
      manHours: "",

      excelCurrencyFormat: '"£"#,##0.00_);[Red]\\("£"#,##0.00\\)',

      //weekly data form
      weeklyDataFormActive: false,
      formWeekEnding: "",
      form: null,

      formUpdateInvoice: false,

      financeData: [],
      financeHeaders: [
        { text: "Wk/Ending", value: "week_ending" },
        { text: "Week", value: "week" },
        { text: "Month", value: "month" },
        { text: "Year", value: "year" },
        { text: "DRev/Ord", value: "d_rev_per_ord" },
        { text: "Revenue", value: "revenue" },
        { text: "Storage", value: "storage" },
        { text: "Handling", value: "handling" },
        { text: "Handling Per Man hour", value: "handling_per_mhr" },
        { text: "Handling Per Order", value: "handling_per_ord" },
        { text: "Total Rev", value: "total_rev" },
        { text: "Rev/Mhr", value: "rev_per_mhr" },
        { text: "Rev/Ord", value: "rev_per_ord" },
        { text: "Rev/Loc", value: "rev_per_loc" },
        { text: "Strev/Loc", value: "strev_per_loc" },
        { text: "Total Units In Stock", value: "total_units_stock" },
        { text: "Units Shipped Vs Stock", value: "units_shipped_vs_stock" },
        { text: "Admin Revenue", value: "admin_revenue" },
        { text: "Actions", value: "actions"}
      ],
    };
  },
  created() {
    this.getInvoiceNumbers();
    },
  methods: {
    // Export Settings
    exportToExcel(type) {
      var wb2 = XLSX.utils.book_new();
      var ws2 = XLSX.utils.json_to_sheet(this.financeData);
      XLSX.utils.book_append_sheet(wb2, ws2, "WeeklyData");
      XLSX.writeFile(wb2, "financeData" + "." + type);
    },
    openDialog() {
      this.invoiceNumber = "";
      this.manHours = "";
      this.dialog = true;
    },
    close() {
      this.invoiceNumber = "";
      this.manHours = "";
      this.dialog = false;
    },
    async createWeeklyData() {
      let invoiceNumber = this.invoiceNumber;
      let manHours = this.manHours;
      if (!invoiceNumber) {
        this.text = `Invoice Number Required`;
        this.loading = false;
        return;
      } else if (!manHours) {
        this.text = `Man Hours Required`;
        this.loading = false;
        return;
      }
      this.loading = true;
      FinanceService.getWeeklyFinanceData(invoiceNumber, manHours)
        .then(
          ((response) => {
            this.loading = false;
            if (response.success) {
              this.snackbar = true;
              this.text = `Weekly Data loaded`;

              this.populateForm(response.data);
            } else {
              this.snackbar = true;
              this.text = response.message;
            }
            this.close();
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.response.data.message}`;
          this.loading = false;
          this.close();
          return false;
        });
    },
    async getInvoiceNumbers() {
      // Use the BillingSnap to call the getBillingData() method
      return CommitedData.getInvoiceNumbers()
        .then(
          ((invoiceNumbers) => {
            this.$set(this, "invoiceNumbers", invoiceNumbers);
            return true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },
    async submitWeeklyData() {
      if (this.formUpdateInvoice) {
        this.form.update = "true";
      }
      FinanceService.submitWeeklyFinanceData(this.form)
        .then(
          ((response) => {
            if (response.success) {
              this.snackbar = true;
              this.text = response.data;
              this.cancelWeeklyForm();
            }

            this.loading = false;
            return true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.response.data.message}`;
          this.loading = false;
          return false;
        });
    },
    populateForm(data) {
      this.formUpdateInvoice = false;
      this.form = data;
      this.weeklyDataFormActive = true;
    },
    cancelWeeklyForm() {
      this.form = {
        week_ending: null,
        week: null,
        month: null,
        year: null,
        occupied_locations: null,
        total_locations: null,
        utilisation: null,
        man_hours: null,
        wh_revenue: null,
        w_rev_per_mhr: null,
        orders: null,
        lines: null,
        pieces: null,
        distribution_revenue: null,
        d_rev_per_ord: null,
        revenue: null,
        storage: null,
        handling: null,
        handling_per_mhr: null,
        handling_per_ord: null,
        total_rev: null,
        rev_per_mhr: null,
        rev_per_ord: null,
        rev_per_loc: null,
        strev_per_loc: null,
        totalUnitsInStock: null,
        units_shipped_vs_stock: null,
        rev_per_line: null,
        activity_revenue_per_man_hour: null,
        direct_revenue: null
      };
      this.weeklyDataFormActive = false;
    },
    editWeeklyData(item){
       this.invoiceNumber = this.week_ending;
      this.manHours = item.man_hours;
      this.populateForm(item);
    },

    exportData(data) {
      let weeklyData = [];

      //weekly data
      for (let i = 0; i < data.length; i++) {
        let row = data[i];
        let tempObj = {
          "Wk/Ending": row.week_ending,
          Week: parseInt(row.week ? row.week : 0),
          Month: parseInt(row.month ? row.month : 0),
          Year: parseInt(row.year ? row.year : 0),
          "Occuppied Locations": parseInt(row.occupied_locations ? row.occupied_locations : 0),
          "Total Locations": parseInt(row.total_locations ? row.total_locations : 0),
          Utilisation: parseFloat(row.utilisation ? row.utilisation : 0),
          "Man Hours": parseInt(row.man_hours ? row.man_hours : 0),
          "WH Revenue": parseFloat(row.wh_revenue ? row.wh_revenue : 0),
          "WRev/Mhr": parseFloat(row.w_rev_per_mhr ? row.w_rev_per_mhr : 0),
          Orders: parseInt(row.orders ? row.orders : 0),
          Lines: parseInt(row.lines ? row.lines : 0),
          Pieces: parseInt(row.pieces ? row.pieces : 0),
          "Distribution Revenue": parseFloat(row.distribution_revenue ? row.distribution_revenue : 0),
          "DRev/Ord": parseFloat(row.d_rev_per_ord ? row.d_rev_per_ord : 0),
          "Freighter Revenue": parseFloat(row.revenue ? row.revenue : 0),
          Storage: parseFloat(row.storage ? row.storage : 0),
          Handling: parseFloat(row.handling ? row.handling : 0),
          "Handling Per Man hour": parseFloat(row.handling_per_mhr ? row.handling_per_mhr : 0),
          "Handling Per Order": parseFloat(row.handling_per_ord ? row.handling_per_ord : 0),
          "Total Rev": parseFloat(row.total_rev ? row.total_rev : 0),
          "Rev/Mhr": parseFloat(row.rev_per_mhr ? row.rev_per_mhr : 0),
          "Rev/Ord": parseFloat(row.rev_per_ord ? row.rev_per_ord : 0),
          "Rev/Loc": parseFloat(row.rev_per_loc ? row.rev_per_loc : 0 ),
          "Strev/Loc": parseFloat(row.strev_per_loc ? row.strev_per_loc : 0),
          "Total Units In Stock": parseInt(row.total_units_stock ? row.total_units_stock : 0),
          "Units Shipped Vs Stock": row.units_shipped_vs_stock ? row.units_shipped_vs_stock : 0,
          "Admin Revenue": row.admin_revenue ? row.admin_revenue : 0 
        };
        weeklyData.push(tempObj);
      }
      //write to excel file
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(weeklyData);
      for (let col of [8, 9, 13, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]) {
        this.formatExcelColumn(ws, col, this.excelCurrencyFormat);
      }

      XLSX.utils.book_append_sheet(wb, ws, "WeeklyData");

      //second sheet
      let lastTwelveWeeks = weeklyData.slice(
        Math.max(weeklyData.length - 12, 0)
      );

      let min = {
        Year: "Min",
        Locations: 0,
        Utilisation: 0,
        "Man Hours": 0,
        "WH Revenue": 0,
        "Distribution Revenue": 0,
        "Freighter Revenue": 0,
        Total: 0,
        Orders: 0,
        Lines: 0,
        Pieces: 0,
        "TRev / Mhr": 0,
        "WRev/Mhr": 0,
        "DRev/Ord": 0,
        "TRev/Loc": 0,
        Storage: 0,
        Handling: 0,
        "Handling Per Man hour": 0,
        "Handling Per Order": 0,
        "Admin Revenue" : 0,
      };

      let max = {
        Year: "Max",
        Locations: 0,
        Utilisation: 0,
        "Man Hours": 0,
        "WH Revenue": 0,
        "Distribution Revenue": 0,
        "Freighter Revenue": 0,
        Total: 0,
        Orders: 0,
        Lines: 0,
        Pieces: 0,
        "TRev / Mhr": 0,
        "WRev/Mhr": 0,
        "DRev/Ord": 0,
        "TRev/Loc": 0,
        Storage: 0,
        Handling: 0,
        "Handling Per Man hour": 0,
        "Handling Per Order": 0,
        "Admin Revenue" : 0,
      };

      let average = {
        Year: "Average",
        Locations: 0,
        Utilisation: 0,
        "Man Hours": 0,
        "WH Revenue": 0,
        "Distribution Revenue": 0,
        "Freighter Revenue": 0,
        Total: 0,
        Orders: 0,
        Lines: 0,
        Pieces: 0,
        "TRev / Mhr": 0,
        "WRev/Mhr": 0,
        "DRev/Ord": 0,
        "TRev/Loc": 0,
        Storage: 0,
        Handling: 0,
        "Handling Per Man hour": 0,
        "Handling Per Order": 0,
        "Admin Revenue" : 0,
      };

      for (let j = 0; j < lastTwelveWeeks.length; j++) {
        delete lastTwelveWeeks[j]["Wk/Ending"];
        lastTwelveWeeks[j].Days = 84 - 7 * j;
        lastTwelveWeeks[j].Freq = j + 1;

        //rename columns
        lastTwelveWeeks[j]["TRev / Mhr"] = lastTwelveWeeks[j]["Rev/Mhr"];
        delete lastTwelveWeeks[j]["Rev/Mhr"];

        lastTwelveWeeks[j]["Total"] = lastTwelveWeeks[j]["Total Rev"];
        delete lastTwelveWeeks[j]["Total Rev"];

        lastTwelveWeeks[j]["Locations"] =
          lastTwelveWeeks[j]["Occuppied Locations"];
        delete lastTwelveWeeks[j]["Occuppied Locations"];

        lastTwelveWeeks[j]["TRev/Loc"] = lastTwelveWeeks[j]["Rev/Loc"];
        delete lastTwelveWeeks[j]["Rev/Loc"];

        //delete unused columns
        delete lastTwelveWeeks[j]["Total Locations"];
        delete lastTwelveWeeks[j]["Rev/Ord"];
        delete lastTwelveWeeks[j]["Strev/Loc"];
        delete lastTwelveWeeks[j]["Total Units In Stock"];
        delete lastTwelveWeeks[j]["Units Shipped Vs Stock"];
        delete lastTwelveWeeks[j]["Revenue"];

        //compute mins and max
        let keys = Object.keys(lastTwelveWeeks[j]);
        for (let x = 0; x < keys.length; x++) {
          let key = keys[x];
          if (
            key !== "Days" &&
            key !== "Freq" &&
            key !== "Month" &&
            key !== "Week" &&
            key !== "Year"
          ) {
            //get min

            if (j === 0 || lastTwelveWeeks[j][key] < min[key]) {
              min[key] = lastTwelveWeeks[j][key];
            }
            //get max
            if (lastTwelveWeeks[j][key] > max[key]) {
              max[key] = lastTwelveWeeks[j][key];
            }

            //sum results for average
            average[key] += parseFloat(lastTwelveWeeks[j][key]);
          }
        }
      }

      //calculate average row
      let objKeys = Object.keys(average);
      for (let x = 0; x < objKeys.length; x++) {
        let key = objKeys[x];
        if (
          key !== "Days" &&
          key !== "Freq" &&
          key !== "Month" &&
          key !== "Week" &&
          key !== "Year"
        ) {
          if (
            key === "Locations" ||
            key === "Orders" ||
            key === "Lines" ||
            key === "Pieces" ||
            key === "Man Hours"
          ) {
            average[key] = Math.round(average[key] / lastTwelveWeeks.length);
          } else {
            average[key] = this.formatCurrency(
              average[key] / lastTwelveWeeks.length
            );
          }
        }
      }
      //compute mix max and average for lastTwelveWeeks
      let current = lastTwelveWeeks.slice(-1);
      current = current.map((a) => ({ ...a }));
      current = current[0];
      current.Year = "Current";
      current.Days = "";
      current.Freq = "";
      current.Month = "";
      current.Week = "";

      let summary = [];
      summary.push(current);
      summary.push(min);
      summary.push(max);
      summary.push(average);

      let cols = [
        "Days",
        "Freq",
        "Month",
        "Week",
        "Year",
        "Locations",
        "Utilisation",
        "Man Hours",
        "WH Revenue",
        "Distribution Revenue",
        "Freighter Revenue",
        "Total",
        "Orders",
        "Lines",
        "Pieces",
        "TRev / Mhr",
        "WRev/Mhr",
        "DRev/Ord",
        "TRev/Loc",
        "Storage",
        "Handling",
        "Handling Per Man hour",
        "Handling Per Order",
        "Admin Revenue"
      ];

      //write summary and last twelve weeks on same sheet
      let ws2 = XLSX.utils.json_to_sheet(summary, {
        header: cols,
        skipHeader: true,
      });
      XLSX.utils.sheet_add_json(ws2, lastTwelveWeeks, {
        header: cols,
        origin: "A6",
      });

      //format columns for excel
      //currency format

      for (let col of [8, 9, 10, 11, 15, 16, 17, 18, 19, 20, 21, 22]) {
        ws2 = this.formatExcelColumn(ws2, col, this.excelCurrencyFormat);
      }

      XLSX.utils.book_append_sheet(wb, ws2, "Last12Weeks");

      XLSX.writeFile(wb, "financeData.xlsx");
    },
    formatExcelColumn(worksheet, col, fmt) {
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      // note: range.s.r + 1 skips the header row
      for (let row = range.s.r; row <= range.e.r; ++row) {
        const ref = XLSX.utils.encode_cell({ r: row, c: col });
        if (worksheet[ref] && worksheet[ref].t === "n") {
          worksheet[ref].z = fmt;
        }
      }
      return worksheet;
    },

    formatCurrency(number) {
      return Math.round(number * 100) / 100;
    },

    async getFinanceData(exportData) {
      this.loading = true;
      this.search = "";

      return FinanceService.getFinanceData()
        .then(
          ((response) => {
            this.$set(this, "financeData", response.data);
            if (exportData) {
              this.exportData(response.data);
            }
            this.snackbar = true;
            this.text = `Finance Data loaded`;
            this.loading = false;
            return true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },
    roundDecimal(num){
      return Math.round(num * 100) / 100
    },
    computeTotalRevenue(){
      let admin = this.roundDecimal(this.form.admin_revenue)
      let warehouse = this.roundDecimal(this.form.wh_revenue)
      let distribution = this.roundDecimal(this.form.distribution_revenue)
      let freighter = this.roundDecimal(this.form.revenue)
      let handling = this.roundDecimal(this.form.handling)
      let storage = this.roundDecimal(this.form.storage)
      let direct = this.roundDecimal(this.form.direct_revenue)

      let manHours = parseInt(this.form.man_hours)
      let orders = parseInt(this.form.orders)
      let lines = parseInt(this.form.lines)
      let occupiedLocations = parseInt(this.form.occupied_locations)

       //update values
       //Total Revenu
      this.form.total_rev = this.roundDecimal(admin + warehouse + distribution + freighter)

      //WRev/Mhr
      this.form.w_rev_per_mhr = this.roundDecimal( warehouse / manHours)
      //DRev/Ord
      this.form.d_rev_per_ord = this.roundDecimal(distribution / orders)
      //Handling/Mhr
      this.form.handling_per_mhr = this.roundDecimal(handling / manHours)
      //handling/ord
      this.form.handling_per_ord = this.roundDecimal(handling / orders)
      //Rev/Mhr
      this.form.rev_per_mhr = this.roundDecimal( this.form.total_rev / manHours)
      // Rev/Ord
      this.form.rev_per_ord = this.roundDecimal(this.form.total_rev / orders)
      // Rev/Loc 
      this.form.rev_per_loc = this.roundDecimal(this.form.total_rev / occupiedLocations )
     // Strev/Loc
      this.form.strev_per_loc = this.roundDecimal(storage / occupiedLocations)
      // ActivityRev/Mhr
      this.form.activity_revenue_per_man_hour = this.roundDecimal(direct / manHours)
      // Rev/Line
      this.form.rev_per_line = this.roundDecimal(direct / lines)
    },
   
   
  },

  computed: {},
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.errorMessage {
  font-size: smaller;
  margin-top: 12px;
  p {
    margin-bottom: 0px;
  }
}
</style>
